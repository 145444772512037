
// Activate all Bootstrap neccessary plugin
$(function () 
{
  $('[data-toggle="tooltip"]').tooltip()
})



var isAdvancedBatterySelectorResponsive = false;

InitializeResponsiveBatterySelector = function () {

    if (isAdvancedBatterySelectorResponsive)
        return
    else
    {
        $('.battery-finder__select').addClass('collapsed');
        $('.battery-finder__select').removeClass('active');

        $('.battery-finder__icon').click(function () {
            $('.battery-finder__select').toggleClass('collapsed')
            $(this).toggleClass('active');
        });

        isAdvancedBatterySelectorResponsive = true;
    }
};


$( document ).ready(function() {

    if ( $(window).width() <= 768 ) {
        InitializeResponsiveBatterySelector();
    }

});

$( window ).resize(function() {
    if ( $(window).width() <= 768 ) {
        InitializeResponsiveBatterySelector();
    }
});


$('#mobile-menu').click(function () {
    $(this).toggleClass('active');
    $('#navigation').toggleClass('active');
    $('#header-location').toggleClass('active');
});